@tailwind base;
@tailwind components;
@tailwind utilities;

*:focus {
  outline: none;
}

@font-face {
  font-family: "Impact";
  src: url("../src/assets/fonts/Impact.eot");
  src: url("../src/assets/fonts/Impact.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/Impact.woff2") format("woff2"),
    url("../src/assets/fonts/Impact.woff") format("woff"),
    url("../src/assets/fonts/Impact.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  overflow-x: hidden;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica Neue",
    sans-serif, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
  position: relative;
}
.font-impact {
  font-family: "Impact", "Roboto";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.txt--success {
  color: #8abd9e;
}
.txt--default {
  color: #2c2c2c;
}
.pointer-icn {
  cursor: pointer;
}
p.header-size {
  font-size: calc(0.9vw + 1.6vh);
}

.txt-title {
  font-size: 24px;
}
.txt-descr {
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 10px;
  line-height: 1.6;
  font-size: 12px;
}
.txt-descr.clamp-tiny {
  -webkit-line-clamp: 12;
}

.txt-descr.clamp-xs {
  -webkit-line-clamp: 15;
}
.txt-descr.clamp-sm {
  -webkit-line-clamp: 18;
}
.txt-descr.clamp-md {
  -webkit-line-clamp: 24;
}
.txt-descr.clamp-xl {
  -webkit-line-clamp: 30;
}
.txt-descr.clamp-lg {
  -webkit-line-clamp: 40;
}

.txt-descr.t1c1 {
  -webkit-line-clamp: 20;
}
.txt-descr.t1c2 {
  -webkit-line-clamp: 5;
}
.txt-descr.t1c3 {
  -webkit-line-clamp: 5;
}
.txt-descr.t1c4 {
  -webkit-line-clamp: 20;
}

.txt-descr.t2c1 {
  -webkit-line-clamp: 17;
}
.txt-descr.t2c2 {
  -webkit-line-clamp: 5;
}
.txt-descr.t2c3 {
  -webkit-line-clamp: 3;
}
.txt-descr.t2c4 {
  -webkit-line-clamp: 8;
}
.txt-descr.t2c5 {
  -webkit-line-clamp: 5;
}
.txt-descr.t2c6 {
  -webkit-line-clamp: 3;
}
.txt-descr.t2c7 {
  -webkit-line-clamp: 6;
}
.txt-descr.t2c8 {
  -webkit-line-clamp: 4;
}

.txt-descr.t3c1 {
  -webkit-line-clamp: 18;
}
.txt-descr.t3c2 {
  -webkit-line-clamp: 5;
}
.txt-descr.t3c3 {
  -webkit-line-clamp: 17;
}
.txt-descr.t3c4 {
  -webkit-line-clamp: 12;
}
.txt-descr.t3c5 {
  -webkit-line-clamp: 3;
}
.txt-descr.t3c6 {
  -webkit-line-clamp: 4;
}
.txt-descr.t3c7 {
  -webkit-line-clamp: 6;
}
.txt-descr.t3c8 {
  -webkit-line-clamp: 4;
}

@media screen and (max-width: 767px) {
  .txt-descr.t1c1 {
    -webkit-line-clamp: 15;
  }
  .txt-descr.t1c2 {
    -webkit-line-clamp: 5;
  }
  .txt-descr.t1c4 {
    -webkit-line-clamp: 7;
  }

  .txt-descr.t2c5 {
    -webkit-line-clamp: 14;
  }
  .txt-descr.t2c7 {
    -webkit-line-clamp: 16;
  }
}

@media screen and (max-width: 767px) {
  .font-min-10 {
    font-size: 10px;
  }
}

.sticky-header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 99;
  margin: 0;
  padding: 20px 0;
  top: 68.5px;
}
.sticky-header::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
  bottom: 0;
  width: 92.5%;
  left: 50%;
  transform: translateX(-50%);
}

.about-head {
  font-size: 10rem;
  line-height: 1;
}
@media screen and (max-width: 1920) {
  .about-head {
    font-size: 8rem;
  }
}
@media screen and (max-width: 1450px) {
  .about-head {
    font-size: 7.5rem;
  }
}
@media screen and (max-width: 1280px) {
  .about-head {
    font-size: 5.5rem;
  }
  .txt-descr.clamp-lg {
    -webkit-line-clamp: 20;
  }
}
@media screen and (max-width: 920px) {
  .about-head {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 670px) {
  .about-head {
    font-size: 3.75rem;
  }
}
@media screen and (max-width: 480px) {
  .about-head {
    font-size: 3.25rem;
  }
}

@media screen and (max-width: 900px) {
  .sticky-header {
    top: 62.5px;
  }
  .txt-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .sticky-header {
    top: 57px;
  }
}
.bg-about {
  background: #717171;
}

.img-fluid {
  width: 100%;
  max-width: auto;
}

.banner-img {
  width: 100%;
}
@media screen and (min-width: 1410px) and (max-width: 1430px) {
  .banner-img {
    width: 99%;
  }
}
@media screen and (min-width: 1530px) and (max-width: 1550px) {
  .banner-img {
    width: 99%;
  }
}
@media screen and (min-width: 1900px) and (max-width: 1940px) {
  .banner-img {
    width: 99%;
  }
}
@media screen and (min-width: 1260px) and (max-width: 1280px) {
  .banner-img {
    width: 98.9%;
  }
}


.img-base {
  width: 100%;
  opacity: 0;
  max-width: fit-content !important;
}
.right-gutter {
  margin-right: 8px;
}
.left-gutter {
  margin-left: 8px;
}
.xs-gutter-top {
  margin-top: 0;
}
.ReactModal__Overlay, .yarl__portal {
  height: calc(100vh - 64.5px);
  top: 64.5px !important;
  /* background-color: rgba(0, 0, 0, 0.8) !important; */
}
/* .ril__outer {
  background-color: rgba(0, 0, 0, 0.95) !important;
} */
@media screen and (max-width: 640px) {
  .ReactModal__Overlay, .yarl__portal {
    height: calc(100vh - 56px);
    top: 56px !important;
  }
  .img-base:not([is-admin="true"]) {
    display: none;
  }
  .right-gutter:not([is-admin="true"]) {
    margin-right: 0;
  }
  .left-gutter:not([is-admin="true"]) {
    margin-left: 0;
  }
  .xs-gutter-top:not([is-admin="true"]) {
    margin-top: 8px;
  }
  .txt-descr:not([is-admin="true"]) {
    -webkit-line-clamp: inherit;
    font-size: 3.5vw;
  }

  .txt-descr.clamp-xs,
  .txt-descr.clamp-sm,
  .txt-descr.clamp-md,
  .txt-descr.clamp-xl,
  .txt-descr.clamp-tiny,
  .txt-descr.clamp-lg .txt-descr.clamp-tiny:not([is-admin="true"]) {
    -webkit-line-clamp: inherit;
  }
  .app-menu{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .cnt-box{
    min-width: 80%;
  }
}

.dcontent::-webkit-scrollbar,
.dcontent::-webkit-scrollbar-corner {
  background: transparent;
  width: 3px;
}

.dcontent::-webkit-scrollbar-thumb {
  background: #fff;
  -webkit-border-radius: 1ex;
}


.ril__caption,
.ril__toolbar {
  background-color: transparent !important;
}
.ril__captionContent {
  text-align: center ;
  width: 100%;
}

.yarl__container{
  background-color: rgba(0, 0, 0, 0.96) !important;
}
.yarl__slide_description{
  text-align: center !important;
}

